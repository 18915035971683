import { ref, watch, computed, onUnmounted } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'
import systemCodesStoreModule from '../systemCodesStoreModule'
// import useSystemCodes from '@/views/system-settings/system-codes/useSystemCodes'

export default function useSystemCodesList() {
  const SYSTEM_CODES_APP_STORE_MODULE_NAME = 'app-system-codes'

  // Register module
  if (!store.hasModule(SYSTEM_CODES_APP_STORE_MODULE_NAME)) store.registerModule(SYSTEM_CODES_APP_STORE_MODULE_NAME, systemCodesStoreModule)

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(SYSTEM_CODES_APP_STORE_MODULE_NAME)) store.unregisterModule(SYSTEM_CODES_APP_STORE_MODULE_NAME)
  })

  // Use toast
  const toast = useToast()

  const refSystemCodeListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'description', sortable: true },
    { key: 'tag', sortable: true },
  ]
  const perPage = ref(10)
  const totalRecords = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const descriptionFilter = ref(null)
  const tagFilter = ref(null)
  const codeFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refSystemCodeListTable.value ? refSystemCodeListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRecords.value,
    }
  })

  const refetchData = () => {
    refSystemCodeListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, descriptionFilter, tagFilter, codeFilter], () => {
    refetchData()
  })

  const fetchSystemCodes = (ctx, callback) => {
    store
      .dispatch('app-system-codes/fetchSystemCodes', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        description: descriptionFilter.value,
        tag: tagFilter.value,
        codes: codeFilter.value,
      })
      .then(response => {
        if (!response.data.data) {
          const records = []
          callback(records)
          return
        }
        const records = response.data.data

        callback(records)
        totalRecords.value = records.length
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching System Codes list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchSystemCodes,
    tableColumns,
    perPage,
    currentPage,
    totalRecords,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refSystemCodeListTable,

    refetchData,

    // Extra Filters
    descriptionFilter,
    tagFilter,
    codeFilter,
  }
}
